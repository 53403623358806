import store from '@/store'
import router from '@/router'
import { foundryRequest } from '@/config/authConfig';
import { ref, getCurrentInstance } from '@vue/composition-api'
import { kFormatter } from '@core/utils/filter'


export default function useDashboardSales() {
  
    const vm = getCurrentInstance().proxy
    
    const refreshRevenue = ref(false)
    const revenueData = ref([])
    
    const refreshSales = ref(false)
    const salesData = ref([])

    
    const labelsFormatter = (value) => {
      return kFormatter(value, 0)+'€'
    }

    const fetchRevenue = async (params) => {
        refreshRevenue.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        store
          .dispatch('app-sales-dashboard/fetchRevenue', {
            ...params
          })
          .then(response => {
            revenueData.value = flattenData(response.data)
            
            refreshRevenue.value = false
          })
          .catch(error => {
            console.log(error)
            if (error.response.status === 404) {
              revenueData.value = []
            }
            if (error.response.status === 401) router.push({ name: 'misc-not-authorized' })
          })
    }

    const fetchSales = async (params) => {
      refreshSales.value = true
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store
        .dispatch('app-sales-dashboard/fetchSales', {
          ...params
        })
        .then(response => {
          const {data} = response
          salesData.value = flattenData(data)
          
          refreshSales.value = false
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 404) {
            revenueData.value = []
          }
          if (error.response.status === 401) router.push({ name: 'misc-not-authorized' })
        })
  }

    const flattenData = (data) => {
      return data.map((item) => {
        return {
          ...item,
          year: parseInt(item.date.substring(0,4)),
          // month: `${dayjs(item.date).get('month')+1}-${this.monthMapping[dayjs(item.date).get('month')]}`,
          month: parseInt(item.date.substring(5,7)),
          week: getWeek(new Date(item.date)),
          day: parseInt(item.date.substring(8,10))
        }
      });
    }
    const getWeek = (date) => {
      var janOne = new Date(date.getFullYear(), 0, 2);
      return Math.ceil((((date - janOne) / 86400000) + date.getDay() + 1) / 7);
    }

    const downloadProjects = async ({from, to}) => {
      
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store
        .dispatch('app-sales-dashboard/downloadProjects', {
          from,
          to
        })
        .then(response => {
          
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement('a');
    
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `Report - ${new Date().toJSON()}.xlsx`);
          document.body.appendChild(fileLink);
    
          fileLink.click();
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 404) {
            revenueData.value = []
          }
          if (error.response.status === 401) router.push({ name: 'misc-not-authorized' })
        })

    }

    const downloadCSAT = async ({from, to}) => {
      
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store
        .dispatch('app-sales-dashboard/downloadCSAT', {
          from,
          to
        })
        .then(response => {
          const data = response.data
          // console.log(flatten(data))
          // const filtered = data.map(obj => {

          //     // get totals to add them later to keep column order (or use `header` param for columns order)
          //     return flatten(obj)
          // });
          let fileURL = window.URL.createObjectURL(new Blob([data]));
          let fileLink = document.createElement('a');
    
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `CSAT - ${new Date().toJSON()}.xlsx`);
          document.body.appendChild(fileLink);
    
          fileLink.click();
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 404) {
            revenueData.value = []
          }
          if (error.response.status === 401) router.push({ name: 'misc-not-authorized' })
        })

    }

    return {
        refreshRevenue,
        revenueData,
        refreshSales,
        salesData,
        labelsFormatter,

        fetchRevenue,
        fetchSales,
        downloadProjects,
        downloadCSAT
    }

}