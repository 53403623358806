<template>
  <div>


    <!-- <v-list
          dense
          >
        <v-list-group
          no-action
          :prepend-icon="icons.mdiDragVertical"
          class="btn-draggable text-sm ml-0 pv-icon"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{field.label}}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item 
            v-for="(item, index) in fieldValues" :key="index"
            class="px-0"
          >
            <v-checkbox
              dense
              :label="item.value.toString()"
              v-model="item.checked"
            ></v-checkbox>
          </v-list-item>
        </v-list-group>

    </v-list> -->

    <v-menu
      offset-y
      :close-on-content-click="false"
      max-height="400px"
      dense
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          :class="`btn-draggable text-sm flex align-center rounded ${variant} white--text`"
          max-height="32px">
            
            <v-icon 
              left
              color="white"
              @click.native.stop
            >{{ icons.mdiDragVertical }}
          </v-icon>
            
          <span>{{ field.label }}</span>
          <v-btn
            small
            icon
            @click="showList = !showList"
          >
            <v-icon
              color="white">{{showList ? icons.mdiMenuUp : icons.mdiMenuDown}} </v-icon>
          </v-btn>
        </div>
        
      </template>
      <v-list
        dense>
        <v-list-item v-if="field.valueFilter">
          <v-checkbox
            dense
            :label="allValuesSelected ? unselectAllText : selectAllText"
            @click="toggleAllValues(!allValuesSelected)"
            hide-details
          ></v-checkbox>
        </v-list-item>
        <v-divider class="mb-2"></v-divider>
        <v-list-item 
          v-for="(item, index) in fieldValues" :key="index"
        >
          <v-checkbox
            dense
            :label="item.value.toString()"
            v-model="item.checked"
          ></v-checkbox>
        </v-list-item>
      </v-list>
      

    </v-menu>
    
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { mdiDragVertical, mdiMenuDown, mdiMenuUp } from '@mdi/js';

export default {
  model: {
    prop: 'fieldValues',
  },
  props: {
    fieldValues: {
      type: Array
    },
    field: {
      type: Object
    },
    variant: {
      type: String,
      default: 'primary'
    },
    selectAllText: {
      type: String
    },
    unselectAllText: {
      type: String
    }
  },
  setup (props) {
    const selectedValue = ref([])
    const list = ref([
      {name: 'HR', value:'HR'},
      {name: 'Inno', value:'Inno'},
      {name: 'Insight', value:'Insight'},
    ])
    const showDropdown = ref(false)
    const hasDropdown = computed(() => {
      return props.field.headerAttributeFilter && props.field.headers || props.field.valueFilter
    })
    const allValuesSelected = computed(() => {
      let allValuesSelected = true

      for (let valueObject of props.fieldValues) {
        if (!valueObject.checked) {
          allValuesSelected = false
          break
        }
      }

      return allValuesSelected
    })
    const toggleShowDropdown = () => {
      showDropdown.value = !showDropdown.value
    }
    const toggleAllValues = (target) => {
      props.fieldValues.forEach(valueObject => {
        valueObject.checked = target
      })
    }
    const showList = ref(false)
    
    return {
      showList,
      selectedValue,
      list,
      showDropdown,
      hasDropdown,
      allValuesSelected,
      toggleShowDropdown,
      toggleAllValues,

      icons: {
        mdiDragVertical,
        mdiMenuUp,
        mdiMenuDown
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* Grid with gutter */
.gutter-x-sm, .gutter-sm {
  margin-left: -.5rem;
  > * {
    margin-left: .5rem;
  }
}

/* Handle icon (mix of grip-vertical & ellipsis-v) */
.svg-inline--fa.fa-w-10 {
  width: 0.625em;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -.125em;
}

.btn-draggable {
  cursor: move !important;
  z-index: 999;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
}

.dropdown-toggle-split {
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
}

.field-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 200px;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-list {
  max-height: 175px;
  overflow: auto;
}

.v-list-group__header .v-list-item .v-list-item--active .v-list-item--link .theme--light {
  padding:0px
}

.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0px;
}

.v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 0px;
}
.v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon{
  min-width: 10px
}

</style>
