var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pivot',{ref:"pivot_table",attrs:{"data":_vm.data,"fields":_vm.fields,"available-field-keys":_vm.availableFieldKeys,"row-field-keys":_vm.rowFieldKeys,"col-field-keys":_vm.colFieldKeys,"reducer":_vm.reducer,"default-show-settings":_vm.defaultShowSettings},scopedSlots:_vm._u([{key:"value",fn:function(ref){
var value = ref.value;
return [(_vm.reduceAttr === 'count')?_c('div',[_vm._v(" "+_vm._s(_vm.number(value))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.amount(value))+" ")])]}},{key:"countryFlagHeader",fn:function(ref){
var value = ref.value;
return [(value)?_c('CIcon',{attrs:{"name":value,"height":"25"}}):_vm._e()]}},{key:"countryNameHeader",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.capitalize(value))+" ")]}},{key:"computing",fn:function(){return [_c('div',{staticClass:"position-absolute w-100 h-100 text-center",staticStyle:{"z-index":"1"}},[_c('div',{staticClass:"position-sticky bg-white d-inline-block mt-5 p-3",staticStyle:{"top":"0"}},[_c('svg',{staticClass:"svg-inline--fa fa-spinner fa-fw fa-pulse",attrs:{"aria-hidden":"true","data-prefix":"fas","data-icon":"spinner","role":"img","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"fill":"currentColor","d":"M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"}})]),_vm._v(" Loading table values... ")])])]},proxy:true},{key:"monthHeader",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.monthMapping[value])+" ")]}},{key:"weekHeader",fn:function(ref){
var value = ref.value;
return [(value !== 0)?_c('div',[_vm._v(" Week "+_vm._s(_vm.number(value))+" ")]):_c('div')]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }