<template>
  <v-row class="match-height">
    <v-col
      cols="12"
      md="12"
    >
      <top-10-sales></top-10-sales>
    </v-col>
    
    <v-col
      cols="12"
      md="12"
    >
    <v-card>
      <v-card-title class="align-start">
        <span class="font-weight-semibold">Sales & Revenue Details</span>
        <v-spacer></v-spacer>
        <v-menu
          v-model="fromMenu"
          transition="scale-transition"
          offset-y
          eager
          min-width="auto"
          :close-on-content-click="false"
          >
          <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="fromDate"
              label="From"
              dense
              hide-details="auto"
              :prepend-icon="icons.mdiCalendarBlankOutline"
              readonly
              v-bind="attrs"
              v-on="on"
              class="mt-0 shrink mr-4"
              
          ></v-text-field>
          </template>
          <v-date-picker
              v-model="fromDate"
              @click:date="saveDates"
              @input="fromMenu = false"
          >
          </v-date-picker>
        </v-menu>
        <v-menu
          v-model="toMenu"
          transition="scale-transition"
          :close-on-content-click="false"
          offset-y
          eager
          min-width="auto"
          >
          <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="toDate"
              label="To"
              dense
              hide-details="auto"
              :prepend-icon="icons.mdiCalendarBlankOutline"
              readonly
              v-bind="attrs"
              v-on="on"
              class="mt-0 shrink mr-4"
              
          ></v-text-field>
          </template>
          <v-date-picker
              v-model="toDate"
              @click:date="saveDates"
              @input="toMenu = false"
          >
          </v-date-picker>
        </v-menu>
        <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :style="`cursor: pointer`"
              >
                <v-list-item-title
                @click="clickDownloadProjects"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDownload }}
                  </v-icon>
                  <span>Download</span>
                </v-list-item-title>
              </v-list-item>

            </v-list>
          </v-menu>
      </v-card-title>

      <v-card-subtitle class="mb-8 mt-n5">
        <span class="font-weight-semibold text--primary me-1">Dashboard</span>
        <span>- Sales, Revenue and Gross Margin data</span>
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col
            v-for="data in statisticsData"
            :key="data.title"
            cols="6"
            md="3"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              :color="resolveStatisticsIconVariation (data.title).color"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ resolveStatisticsIconVariation (data.title).icon }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                {{ data.title }}
              </p>
              <h3 class="text-xl font-weight-semibold">
                {{ data.total }}
              </h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    </v-col>
    
    
    
    <v-col
      cols="12"
      md="12"
    >
      <revenue-table 
        :dates="dates"
        @update-data="updateStatsData"
      ></revenue-table>
    </v-col>


    <v-col
      cols="12"
      md="12"
    >
      <sales-view 
        :dates="dates"
        @update-data="updateStatsData"
      ></sales-view>
    </v-col>

  </v-row>
</template>
<script>
import { onUnmounted, ref, getCurrentInstance } from '@vue/composition-api'
import store from '@/store'
import { kFormatter } from '@core/utils/filter'
import salesStoreModule from './salesStoreModule'
import useSalesDashboard from './useSalesDashboard'

import RevenueTable from './RevenueTable.vue'
import SalesView from './SalesView.vue'
import Top10Sales from './Top10Sales.vue'

// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, 
        mdiDotsVertical, mdiLabelOutline, mdiCalendarBlankOutline,
        mdiDownload, mdiEmoticonOutline } from '@mdi/js'

export default {
  components: {
    RevenueTable,
    SalesView,
    Top10Sales
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const SALES_DASHBOARD_APP_STORE_MODULE_NAME = 'app-sales-dashboard'

    // Register module
    if (!store.hasModule(SALES_DASHBOARD_APP_STORE_MODULE_NAME)) store.registerModule(SALES_DASHBOARD_APP_STORE_MODULE_NAME, salesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SALES_DASHBOARD_APP_STORE_MODULE_NAME)) store.unregisterModule(SALES_DASHBOARD_APP_STORE_MODULE_NAME)
    })

    const { downloadProjects, downloadCSAT } = useSalesDashboard()

    const fromMenu = ref(false)
    const toMenu = ref(false)
    const fromDate = ref(`${new Date().getFullYear()}-01-01`)
    const toDate = ref(`${new Date().getFullYear()}-12-31`)

    const dates = ref([fromDate.value, toDate.value])

    const saveDates = () => {
      dates.value = [fromDate.value, toDate.value]
    }

    const clickDownloadProjects = () => {
      downloadProjects({from:fromDate.value, to:toDate.value})
    }

    const clickDownloadCSAT = () => {
      downloadCSAT({from:fromDate.value, to:toDate.value})
    }


    const statisticsData = ref({
      sales:{
        title: 'Sales',
        total: `0€`,
      },
      revenue:{
        title: 'Revenue',
        total: `0€`,
      },
      gross_margin:{
        title: 'Gross Margin',
        total: `0€`,
      },
      projects:{
        title: 'Projects',
        total: `0`,
      },
    })

    const updateStatsData = (key, val) => {
      if (key == 'projects') statisticsData.value[key].total = `${kFormatter(val)}`
      else statisticsData.value[key].total = `${kFormatter(val)}€`
    }

    const resolveStatisticsIconVariation = data => {
      if (data === 'Sales') return { icon: mdiTrendingUp, color: 'primary' }
      if (data === 'Projects') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'Gross Margin') return { icon: mdiLabelOutline, color: 'warning' }
      if (data === 'Revenue') return { icon: mdiCurrencyUsd, color: 'info' }

      return { icon: mdiAccountOutline, color: 'success' }
    }

    return {
      fromMenu,
      toMenu,
      fromDate,
      toDate,

      dates,
      saveDates,

      statisticsData,
      resolveStatisticsIconVariation,
      clickDownloadProjects,
      clickDownloadCSAT,
      updateStatsData,

      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiCalendarBlankOutline,
        mdiDownload,
        mdiEmoticonOutline,
      },
    }
  }
}
</script>
