<template>
  <app-card-actions
    no-actions
    :refresh="refreshSales"
  >
    <template slot="title">
        <span class="font-weight-semibold align-start pb-0">Top 10 Sales</span>
        <v-spacer></v-spacer>
        <!-- <v-btn-toggle
            mandatory
            dense
            color="warning"
            class="mr-4"
          >
          
            <v-btn
              outlined
              color="warning"
              v-for="split, index in splitOptions"
              :key="index"
            >
              {{split.label}}
            </v-btn>
            
          </v-btn-toggle> -->
          <v-btn-toggle
            mandatory
            dense
            color="primary"
          >
          
            <v-btn
              outlined
              color="primary"
              v-for="time, index in timelineViewOptions"
              :key="index"
              @click="onActiveTimelineViewChange(time)"
            >
              {{time.label}}
            </v-btn>
            
          </v-btn-toggle>
    </template>
    
    <template slot="subtitle">
      <span class="align-start pb-0">Current year {{new Date().getFullYear()}}</span>
    </template>
    <v-row class="ma-0">
      <v-col
        cols="12"
        sm="9"
        class="total-profit-chart-col"
        v-if="salesData"
      >
        <apex-chart
          ref="chart"
          id="total-profit-chart"
          :type="`bar`"
          :stacked="true"
          :data="chartData"
          :flat="true"
          :yaxisLabel="labelsFormatter"
          :plotOptions="plotOptions"
          :tooltip="tooltip"
          :height="320"
          :classApex="`mb-0 pa-0`"
          @click-event="openProjectPage"
        >
          
        </apex-chart>
      </v-col>
      <v-col
        cols="12"
        sm="3"
      >
        <v-card elevation="0">
          <!-- Title/Header -->
          <v-card-title class="align-start pt-0 flex-nowrap">
            <div>
              <p class="mb-0 font-weight-bold text-2xl">
                    {{kFormatter(totalSales)}}€
              </p>
              <small class="text--secondary text-xs text-no-wrap">Last {{ activeTimelineView.label.toLowerCase() }} balance $xx.xk</small>
            </div>
            
          </v-card-title>

          <v-card-text class="pb-3 pt-5">
            <!-- List -->
            <v-list
              two-line
              subheader
            >
              <!-- List Item: Profit -->
              <!-- <v-list-item class="pa-0">
                <v-list-item-avatar
                  class="v-avatar-light-bg primary--text justify-center"
                  size="40"
                  rounded
                >
                  <v-icon
                    size="30"
                    color="primary"
                  >
                    {{ icons.mdiTrendingUp }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-semibold">
                    {{kFormatter(totalSales)}}€
                  </v-list-item-title>
                  <v-list-item-subtitle>Total Sales</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item> -->
              <!-- List Item: Income -->
              <v-list-item class="pa-0">
                <v-list-item-avatar
                  class="v-avatar-light-bg primary--text justify-center"
                  size="40"
                  rounded
                >
                  <v-icon
                    size="30"
                    color="primary"
                  >
                    {{ icons.mdiCurrencyEur }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-semibold">
                    {{kFormatter(totalGM)}}€
                  </v-list-item-title>
                  <v-list-item-subtitle>Total Gross Margin</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!-- List Item: Expense -->
              <v-list-item class="pa-0">
                <v-list-item-avatar
                  class="v-avatar-light-bg success--text justify-center"
                  size="40"
                  rounded
                >
                  <v-icon
                    size="30"
                    color="success"
                  >
                    {{ icons.mdiChartBar }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-semibold">
                    {{kFormatter(totalExpenses)}}€
                  </v-list-item-title>
                  <v-list-item-subtitle>Total Expense</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <!-- Action Button -->
            <v-btn
              block
              color="primary"
              @click="exportCSV"
            >
              Export Report
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </app-card-actions>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import ApexChart from '@/views/components/charts/apex/ApexChart.vue'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import { ref, onMounted, watch, computed } from '@vue/composition-api'
// eslint-disable-next-line object-curly-newline
import { mdiMenuDown, mdiTrendingUp, mdiCurrencyEur, mdiChartBar } from '@mdi/js'
import { kFormatter } from '@core/utils/filter'
import useSalesDashboard from './useSalesDashboard'
import router from '@/router'

export default {
  components: {
    VueApexCharts,
    ApexChart,
    AppCardActions,
  },
  props: {
      dates: {
          type: Array,
          default: () => [`${new Date().getFullYear()}-01-01`, `${new Date().getFullYear()}-12-31`]
      },
  },
  setup(props) {

    const localDates = ref([])
    localDates.value = JSON.parse(JSON.stringify(props.dates)).sort()

    const {
        refreshSales,
        salesData,
        fetchSales,
        labelsFormatter

    } = useSalesDashboard()

    const chartData = ref([
        // {
        //   name: 'Sales',
        //   data: []
        // },
        {
          name: 'Gross Margin',
          data: []
        },
        {
          name: 'Expenses',
          data: []
        }
      ])

    const totalSales = ref(0)
    const totalGM = ref(0)
    const totalExpenses = ref(0)
    const  plotOptions = ref({
        bar: {
            borderRadius: 10,
            columnWidth: '35%',
            startingShape: 'rounded',
            endingShape: 'rounded',
        },
    })

    const splitOptions = ref([
      { label: 'Overall', value: 'overall'},
      { label: 'Office', value: 'office'},
      { label: 'Client', value: 'client'},
    ])

    const timelineViewOptions = computed(()=> {
      
      const today = new Date()
      const quarter = Math.floor(today.getMonth() / 3 + 1)
      const semester = Math.floor(today.getMonth() / 6 + 1)
      return [
        { label: 'Month', value: 'month', first: today.getMonth()+1, last: today.getMonth()+1},
        { label: 'Quarter', value: 'quarter', first: 1+(3*quarter) -3, last: 3+(3*quarter) - 3},
        { label: 'Semester', value: 'semester', first: 1+(6*semester)-6, last: 6+(6*semester)-6},
        { label: 'Year', value: 'year', first: 1, last: 12}
      ]
    })
    const activeTimelineView = ref(timelineViewOptions.value[0])

    const onActiveTimelineViewChange = (item) => {
      activeTimelineView.value = item;
      fetchSalesByTimeline(item)
    }

    const fetchSalesByTimeline = (timeline) => {
        const {first, last} = timeline
        const now = new Date();
        const lastDate = new Date(now.getFullYear(), last, 0) 

        const firstDay = `${now.getFullYear()}-${(first>9 ? '' : '0') + first}-01`
        const lastDay = `${now.getFullYear()}-${(last+1>9 ? '' : '0') + last}-${lastDate.getDate()}`
        fetchSales({
          from: firstDay,
          to: lastDay,
          topsales: 10
        })
        
    }

    const resetChartData = () => {
      // totalSales.value = 0
      totalGM.value = 0
      totalExpenses.value = 0
      var newChartData = [
        // {
        //   name: 'Sales',
        //   data: []
        // },
        {
          name: 'Gross Margin',
          data: []
        },
        {
          name: 'Expenses',
          data: []
        }
      ]

        return { newChartData }

    }

    const openProjectPage = (event) => {
      const project = event.config.series[event.dataPointIndex].data[event.dataPointIndex]
      if (project) router.push({ name: 'project-view', params: {id: project.job_number }})
    }

    const exportCSV = () => {

      const csvContent = "data:text/csv;charset=utf-8,"
                        + [[['Job Number', 'Office', 'Project Type', 'Service Type', 'Business Init', 'Client', 'Category', 'Contact', 'Sales', 'Gross Margin'].join(",")],
                         ...salesData.value.map(item => [
                            item.job_number,
                            item.office,
                            item.project_type,
                            item.service_type,
                            item.business_initiative,
                            item.client,
                            item.category,
                            item.contact,
                            item.budget,
                            item.gross_margin
                         ].map(str => {
                          return String(str).replaceAll(',', ';').replace(/"/g, '\"')
                      }))]
                        .map(e => e.join(",")) 
                        .join("\n");

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement("a")
      link.setAttribute("href", encodedUri)
      link.setAttribute("download", "Top 10 Sales.csv")
      document.body.appendChild(link)

      link.click();
      // vm.$refs.chart.chart.ctx.exports.w.globals.exportToCSV()
    }

    const legend = {
        tooltipHoverFormatter: function(val, opts) {
          return val + ' - ' + kFormatter(opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]) + '€'
        }
      }
      const tooltip = {
        enabled: true,
        shared: true,
        intersect: false,
        custom: function({series, seriesIndex, dataPointIndex, w}) {
          const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]
          var seriesString = ''
          const seriesTitle = data.job_number ? `${data.job_number} - ${data.job_name} (${data.client})` : data.x
          var total = 0
          for (const index in series) {
            const serieName = w.globals.seriesNames[index]
            const serieValue = kFormatter(series[index][dataPointIndex], 0)
            const serieColor = w.globals.colors[index]
            total += series[index][dataPointIndex]
            seriesString += '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">'+
                    '<span class="apexcharts-tooltip-marker" style="background-color: '+ serieColor +';"></span>'+
                    '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">'+
                      '<div class="apexcharts-tooltip-y-group">'+
                        '<span class="apexcharts-tooltip-text-y-label">'+
                          serieName+': '+
                        '</span>'+
                        '<span class="apexcharts-tooltip-text-y-value">'+
                          serieValue+ '€'+
                        '</span>'+
                      '</div>'+
                      '<div class="apexcharts-tooltip-goals-group">'+
                        '<span class="apexcharts-tooltip-text-goals-label"></span>'+
                        '<span class="apexcharts-tooltip-text-goals-value"></span>'+
                      '</div>'+
                      '<div class="apexcharts-tooltip-z-group">'+
                        '<span class="apexcharts-tooltip-text-z-label"></span>'+
                        '<span class="apexcharts-tooltip-text-z-value"></span>'+
                      '</div>'+
                    '</div>'+
                  '</div>'
          }

          return '<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">'+
                  seriesTitle +
                  '</div>'+ seriesString +
                  '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">'+
                    '<span class="apexcharts-tooltip-marker" style="background-color: #FFB400;"></span>'+
                    '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">'+
                      '<div class="apexcharts-tooltip-y-group">'+
                        '<span class="apexcharts-tooltip-text-y-label">'+
                          'Total : '+
                        '</span>'+
                        '<span class="apexcharts-tooltip-text-y-value">'+
                          kFormatter(total)+ '€'+
                        '</span>'+
                      '</div>'+
                      '<div class="apexcharts-tooltip-goals-group">'+
                        '<span class="apexcharts-tooltip-text-goals-label"></span>'+
                        '<span class="apexcharts-tooltip-text-goals-value"></span>'+
                      '</div>'+
                      '<div class="apexcharts-tooltip-z-group">'+
                        '<span class="apexcharts-tooltip-text-z-label"></span>'+
                        '<span class="apexcharts-tooltip-text-z-value"></span>'+
                      '</div>'+
                    '</div>'+
                  '</div>'
        }
      }

    onMounted( () => {
      fetchSalesByTimeline(activeTimelineView.value)
    })

    watch(
      salesData,
      () => {
        var { newChartData } = resetChartData()

        for(const job of salesData.value) {
          // totalSales.value += job.budget
          totalGM.value += job.gross_margin
          totalExpenses.value += job.budget - job.gross_margin
          // newChartData[0].data.push({
          //   y:job.budget,
          //   x:job.job_number,
          //   ...job
          // })
          newChartData[0].data.push({
            y: job.gross_margin,
            x:job.job_number,
            ...job
          })
          newChartData[1].data.push({
            y: job.budget - job.gross_margin,
            x:job.job_number,
            ...job
          })
        }
        
        chartData.value = newChartData
      }
    )
    watch(
      () => props.dates,
      (from, to) => {
          if (from !== to) {
              localDates.value = JSON.parse(JSON.stringify(props.dates)).sort()
          }
      }
    )

    return {
      chartData,
      totalSales,
      totalGM,
      totalExpenses,
      activeTimelineView,
      onActiveTimelineViewChange,
      timelineViewOptions,
      splitOptions,
      refreshSales,
      fetchSalesByTimeline,
      kFormatter,
      salesData,
      labelsFormatter,
      exportCSV,
      legend,
      tooltip,
      openProjectPage,
      plotOptions,
      icons: {
        mdiMenuDown,
        mdiTrendingUp,
        mdiCurrencyEur,
        mdiChartBar,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .total-profit-chart-col {
    @include ltr() {
      border-right: thin solid;
    }
    @include rtl() {
      border-left: thin solid;
    }
  }
}
@include theme--child(total-profit-chart-col) using ($material) {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    border-color: map-get($material, 'dividers');
  }
}
</style>
