var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"match-height"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('top-10-sales')],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"align-start"},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v("Sales & Revenue Details")]),_c('v-spacer'),_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","eager":"","min-width":"auto","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-0 shrink mr-4",attrs:{"label":"From","dense":"","hide-details":"auto","prepend-icon":_vm.icons.mdiCalendarBlankOutline,"readonly":""},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fromMenu),callback:function ($$v) {_vm.fromMenu=$$v},expression:"fromMenu"}},[_c('v-date-picker',{on:{"click:date":_vm.saveDates,"input":function($event){_vm.fromMenu = false}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1),_c('v-menu',{attrs:{"transition":"scale-transition","close-on-content-click":false,"offset-y":"","eager":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-0 shrink mr-4",attrs:{"label":"To","dense":"","hide-details":"auto","prepend-icon":_vm.icons.mdiCalendarBlankOutline,"readonly":""},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.toMenu),callback:function ($$v) {_vm.toMenu=$$v},expression:"toMenu"}},[_c('v-date-picker',{on:{"click:date":_vm.saveDates,"input":function($event){_vm.toMenu = false}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}])},[_c('v-list',[_c('v-list-item',{style:("cursor: pointer")},[_c('v-list-item-title',{on:{"click":_vm.clickDownloadProjects}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")]),_c('span',[_vm._v("Download")])],1)],1)],1)],1)],1),_c('v-card-subtitle',{staticClass:"mb-8 mt-n5"},[_c('span',{staticClass:"font-weight-semibold text--primary me-1"},[_vm._v("Dashboard")]),_c('span',[_vm._v("- Sales, Revenue and Gross Margin data")])]),_c('v-card-text',[_c('v-row',_vm._l((_vm.statisticsData),function(data){return _c('v-col',{key:data.title,staticClass:"d-flex align-center",attrs:{"cols":"6","md":"3"}},[_c('v-avatar',{staticClass:"elevation-1",attrs:{"size":"44","color":_vm.resolveStatisticsIconVariation (data.title).color,"rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.resolveStatisticsIconVariation (data.title).icon)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-xs mb-0"},[_vm._v(" "+_vm._s(data.title)+" ")]),_c('h3',{staticClass:"text-xl font-weight-semibold"},[_vm._v(" "+_vm._s(data.total)+" ")])])],1)}),1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('revenue-table',{attrs:{"dates":_vm.dates},on:{"update-data":_vm.updateStatsData}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('sales-view',{attrs:{"dates":_vm.dates},on:{"update-data":_vm.updateStatsData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }