<template>
    <app-card-actions
     class="mb-7"
      no-actions
      :refresh="refreshRevenue"
    >
        <template slot="title" >
            <span class="align-start">Revenue Recognition</span>
            
        </template>
        <v-card-text>
            <v-row
                class="align-center justify-center"
            >
                <v-btn-toggle
                    v-model="reduceAttr"
                    mandatory
                    rounded
                    dense
                    @change="reducerUpdate($event)"
                >
                    <v-btn :value="`budget`" small>
                        Revenue
                    </v-btn>
                    <v-btn :value="`gross_margin`" small>
                        Gross Margin
                    </v-btn>
                </v-btn-toggle>
            </v-row>
            <project-pivot-table
                ref="revenue_pivot_table"
                :data="revenueData"
                :reducer="reducer"
                :reduceAttr="reduceAttr">

            </project-pivot-table>
        </v-card-text>  
    </app-card-actions>
  </template>
  
<script>
    import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
    import ProjectPivotTable from './pivot-table/ProjectPivotTable.vue'
    import {  mdiCurrencyEur, mdiNumeric, mdiCalendarBlankOutline } from '@mdi/js'
    import { onMounted, ref, getCurrentInstance, watch } from '@vue/composition-api'
    import useSalesDashboard from './useSalesDashboard'
  
    export default {
        components: {
            AppCardActions,
            ProjectPivotTable,
        },
        props: {
            dates: {
                type: Array,
                default: () => [`${new Date().getFullYear()}-01-01`, `${new Date().getFullYear()}-12-31`]
            },
        },
        setup(props, {emit}) {
            const vm = getCurrentInstance().proxy
            const localDates = ref([])
            localDates.value = JSON.parse(JSON.stringify(props.dates)).sort()
            const {
                refreshRevenue,
                revenueData,
                fetchRevenue

            } = useSalesDashboard()

            const reduceAttr = ref('budget')
            
            const reducer = (sum, item, rows, cols) => {
                return sum + item[reduceAttr.value] || sum ;
            }
            const reducerUpdate = (evt) => {
                // emit('update', evt)
                vm.$refs.revenue_pivot_table.$refs.pivot_table.$refs.pivottable.updateValues(true);
            }


            const fetchRevenueByDate = () => {
                const [from, to] = localDates.value
                fetchRevenue({
                    from,
                    to
                })
            }

            watch(
                () => props.dates,
                (from, to) => {
                    if (from !== to) {
                        localDates.value = JSON.parse(JSON.stringify(props.dates)).sort()
                        fetchRevenueByDate()
                    }
                }
            )

            watch(
                () => revenueData.value,
                () => {
                    let totalRevenue = 0
                    let totalGrossMargin = 0
                    let projects = new Set()
                    for (const item of revenueData.value){
                        totalRevenue += item.budget
                        totalGrossMargin += item.gross_margin
                        projects.add(item.job_number)
                    }
                    emit('update-data', 'revenue', totalRevenue)
                    emit('update-data', 'gross_margin', totalGrossMargin)
                    emit('update-data', 'projects', projects.size)
                }
            )

            onMounted(() => {
                fetchRevenueByDate()
            })

    
            return {
               
                refreshRevenue,
                revenueData,
                reduceAttr,
                localDates,
                icons: {
                    mdiCurrencyEur,
                    mdiNumeric,
                    mdiCalendarBlankOutline,
                },

                reducer,
                reducerUpdate,
            }
        },
    }
</script>

<style lang="scss">
$enable-rounded: false;
$table-cell-padding: .5rem; // default in bs5
$table-cell-padding-sm: .25rem; // default in bs5
// @import '~bootstrap/scss/bootstrap.scss';

/* Table with aria-busy attribute */
table[aria-busy='true'] {
  opacity: 0.6;
}

/* FontAwesome icons */
.svg-inline--fa.fa-fw {
  width: 1.25em;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -.125em !important;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>

  