<template>
    <div>
        <v-data-table
        
            :loading="isDataLoading"
            loading-text="Loading... Please wait"
            hide-default-footer
        >

        </v-data-table>
    </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiFileExcel } from '@mdi/js';

export default({
    props: {
        data: {
            type: Array,
            default: () => []
        },
        rowFields: {
            type: Array,
            default: () => []
        },
        colFields: {
            type: Array,
            default: () => []
        },
        reducer: {
            type: Function,
            default: sum => sum + 1
        },
        reducerInitialValue: {
            default: 0
        },
        noDataWarningText: {
            type: String,
            default: 'No data to display.'
        },
        isDataLoading: {
            type: Boolean,
            default: false
        },
        filename: {
            type: String,
            default: ''
        }
    },
    setup(props) {

        console.log(props.data, props.rowFields, props.colFields, props.reducer)
        watch(
            () => props.data,
            () => {
                console.log(props.data)
            }
        )
            
        return {
            icons: {
                mdiFileExcel
            }
        }
        
    },
})
</script>
