<template>
  <div class="position-relative">
    <template v-if="isDataLoading">
      <slot name="loading">
        Loading...
      </slot>
    </template>
    <template v-else>
      <template v-if="isDataComputing">
        <slot name="computing">
          <div class="position-absolute w-100 h-100 text-center" style="z-index: 1;">
            <div class="position-sticky d-inline-block mt-5 p-3" style="top: 0;">
              Loading table values...
            </div>
          </div>
        </slot>
      </template>

      <div v-if="data.length === 0 || (rows.length === 0 && cols.length === 0)" class="alert alert-warning" role="alert">
        {{ noDataWarningText }}
      </div>

      <div v-else-if="cols.length && rows.length" class="position-relative table-responsive">
        <table class="table table-bordered table-striped table-condensed" :aria-busy="isDataLoading || isDataComputing">

          <!-- Table header -->
          <thead>
            <template v-for="({ colField, cols }, colFieldIndex) in tableHeader.colHeaderFields">
              <!-- Multiple slots -->
              <template v-if="colField.headerSlotNames">
                <tr
                  v-for="(headerSlotName, headerSlotNameIndex) in colField.headerSlotNames"
                  :key="`${colFieldIndex}-${headerSlotNameIndex}`">
                  <!-- Top left dead zone -->
                  <th
                    v-if="colFieldIndex === 0 && rowHeaderSize > 0 && headerSlotNameIndex === 0"
                    :colspan="rowHeaderSize"
                    :rowspan="colHeaderSize">
                  
                  </th>
                  <!-- Column headers -->
                  <th
                    v-for="(col, colIndex) in cols"
                    :key="`${colFieldIndex}-${headerSlotNameIndex}-${colIndex}`"
                    :colspan="col.colspan">
                    <slot :name="headerSlotName" :value="col.value">
                      Missing slot <code>{{ headerSlotName }}</code>
                    </slot>
                  </th>
                  <!-- Top right dead zone -->
                  <th
                    v-if="colFieldIndex === 0 && rowFooterSize > 0 && headerSlotNameIndex === 0"
                    :colspan="rowFooterSize"
                    :rowspan="colHeaderSize">
                  </th>
                </tr>
              </template>
              <!-- Single slot/no slot -->
              <tr v-else :key="colFieldIndex">
                <!-- Top left dead zone -->
                <th
                  v-if="colFieldIndex === 0 && rowHeaderSize > 0"
                  :colspan="rowHeaderSize"
                  :rowspan="colHeaderSize">
                  
                    <v-btn
                      left
                      top
                      text
                      icon
                      color="success"
                      @click="saveTableWithText('csv')">
                      <v-icon>{{icons.mdiFileExcel }}</v-icon>
                    </v-btn>
                  <!-- <button type="button" class="btn btn-success" v-on:click="saveTableWithText('csv')">Export XLS</button> -->
                </th>
                <!-- Column headers -->
                <th
                  v-for="(col, colIndex) in cols"
                  :key="`${colFieldIndex}-${colIndex}`"
                  :colspan="col.colspan">
                  <slot v-if="colField.headerSlotName" :name="colField.headerSlotName" :value="col.value">
                    Missing slot <code>{{ colField.headerSlotName }}</code>
                  </slot>
                  <template v-else>
                    {{ col.value }}
                  </template>
                </th>
                
                <!-- Top right dead zone -->
                <th
                  v-if="colFieldIndex === 0 && rowFooterSize > 0"
                  :colspan="rowFooterSize"
                  :rowspan="colHeaderSize">
                </th>
                <th
                  v-if="colFieldIndex === 0 && rowHeaderSize > 0"
                  :colspan="rowHeaderSize"
                  :rowspan="colHeaderSize"
                  class="error--text">
                  Total
                  </th>
              </tr>
              
            </template>
          </thead>

          <!-- Table body -->
          <tbody>
            <tr v-for="({ row, rowHeaderFields, rowFooterFields }, rowIndex) in tableRows" :key="rowIndex">
              <!-- Row headers -->
              <template v-for="(rowField, rowFieldIndex) in rowHeaderFields">
                <!-- Multiple slots -->
                <template v-if="rowField.headerSlotNames">
                  <th
                    v-for="(headerSlotName, headerSlotNameIndex) in rowField.headerSlotNames"
                    :key="`header-${rowIndex}-${rowFieldIndex}-${headerSlotNameIndex}`"
                    :rowspan="rowField.rowspan">
                    <slot :name="headerSlotName" :value="rowField.value">
                      Missing slot <code>{{ headerSlotName }}</code>
                    </slot>
                  </th>
                </template>
                <!-- Single slot/no slot -->
                <th
                  v-else
                  :key="`header-${rowIndex}-${rowFieldIndex}`"
                  :rowspan="rowField.rowspan">
                  <slot v-if="rowField.headerSlotName" :name="rowField.headerSlotName" :value="rowField.value">
                    Missing slot <code>{{ rowField.headerSlotName }}</code>
                  </slot>
                  <template v-else>
                    {{ rowField.value }}
                  </template>
                </th>
              </template>
              <!-- Values -->
              <td
                v-for="(col, colIndex) in sortedCols"
                :key="`value-${rowIndex}-${colIndex}`"
                class="text-right">
                <slot v-if="$scopedSlots.value" name="value" :value="value(row, col)" :labels="labels(row, col)" />
                <template v-else>{{ value(row, col) }}</template>
              </td>
              <td class="error--text text-right" style="font-weight:bold;">
                <slot v-if="$scopedSlots.value" name="value" :value="sumFieldRow(row)" />
                <template v-else>{{ sumFieldRow(row) }}</template>
              </td>

              <!-- Row footers (if slots are provided) -->
              <template v-for="(rowField, rowFieldIndex) in rowFooterFields">
                <!-- Multiple slots -->
                <template v-if="rowField.footerSlotNames">
                  <th
                    v-for="(footerSlotName, footerSlotNameIndex) in rowField.footerSlotNames"
                    :key="`footer-${rowIndex}-${rowFieldIndex}-${footerSlotNameIndex}`"
                    :rowspan="rowField.rowspan">
                    <slot :name="footerSlotName" :value="rowField.value">
                      Missing slot <code>{{ footerSlotName }}</code>
                    </slot>
                  </th>
                </template>
                <!-- Single slot/no slot -->
                <th
                  v-else
                  :key="`footer-${rowIndex}-${rowFieldIndex}`"
                  :rowspan="rowField.rowspan">
                  <slot v-if="rowField.footerSlotName" :name="rowField.footerSlotName" :value="rowField.value">
                    Missing slot <code>{{ rowField.footerSlotName }}</code>
                  </slot>
                  <template v-else>
                    {{ rowField.value }}
                  </template>
                </th>
              </template>
            </tr>
            <!-- <tr>
              GrandTotal
            </tr> -->
            <tr class="error--text">
              <td style="font-weight:bold;"
              :colspan="rowHeaderSize">Total</td>
              
              <td v-for="(col, colIndex) in sortedCols"
              :key="`total-${colIndex}`"
              class="text-right"
              style="font-weight:bold;">
              
                <slot v-if="$scopedSlots.value" name="value" :value="sumFieldCol(col)"/>
                <template v-else>{{ sumFieldCol(col) }}</template>
              </td>
              <td 
              :key="`total-totals`"
              class="text-right"
              style="font-weight:bold;">
              
                <slot v-if="$scopedSlots.value" name="value" :value="sumFieldTotal()"/>
                <template v-else>{{ sumFieldTotal() }}</template>
              </td>
            </tr>
          </tbody>

          <!-- Table footer -->
          <tfoot>
            <template v-for="({ colField, cols }, colFieldIndex) in tableFooter.colFooterFields">
              <!-- Multiple slots -->
              <template v-if="colField.footerSlotNames">
                <tr
                  v-for="(footerSlotName, footerSlotNameIndex) in colField.footerSlotNames"
                  :key="`${colFieldIndex}-${footerSlotNameIndex}`">
                  <!-- Bottom left dead zone -->
                  <th
                    v-if="colFieldIndex === 0 && rowHeaderSize > 0 && footerSlotNameIndex === 0"
                    :colspan="rowHeaderSize"
                    :rowspan="colFooterSize">
                  </th>
                  <!-- Column footers -->
                  <th
                    v-for="(col, colIndex) in sortedCols"
                    :key="`${colFieldIndex}-${footerSlotNameIndex}-${colIndex}`"
                    :colspan="col.colspan">
                    <slot :name="footerSlotName" :value="col.value">
                      Missing slot <code>{{ footerSlotName }}</code>
                    </slot>
                  </th>
                  <!-- Bottom right dead zone -->
                  <th
                    v-if="colFieldIndex === 0 && rowFooterSize > 0 && footerSlotNameIndex === 0"
                    :colspan="rowFooterSize"
                    :rowspan="colFooterSize">
                  </th>
                </tr>
              </template>
              <!-- Single slot/no slot -->
              <tr v-else :key="colFieldIndex">
                <!-- Bottom left dead zone -->
                <th
                  v-if="colFieldIndex === 0 && rowHeaderSize > 0"
                  :colspan="rowHeaderSize"
                  :rowspan="colFooterSize">
                </th>
                <!-- Column footers -->
                <th
                  v-for="(col, colIndex) in cols"
                  :key="`${colFieldIndex}-${colIndex}`"
                  :colspan="col.colspan">
                  <slot v-if="colField.footerSlotName" :name="colField.footerSlotName" :value="col.value">
                    Missing slot <code>{{ colField.footerSlotName }}</code>
                  </slot>
                  <template v-else>
                    {{ col.value }}
                  </template>
                </th>
                <!-- Bottom right dead zone -->
                <th
                  v-if="colFieldIndex === 0 && rowFooterSize > 0"
                  :colspan="rowFooterSize"
                  :rowspan="colFooterSize">
                </th>
              </tr>
            </template>
          </tfoot>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import { onMounted, computed, ref, watch } from '@vue/composition-api'
import ManyKeysMap from 'many-keys-map'
import { firstBy } from 'thenby'
import naturalSort from 'javascript-natural-sort'
import cloneDeep from 'lodash-es/cloneDeep'
import isEqual from 'lodash-es/isEqual'
import { downloadTableWith } from '@core/utils'
import { mdiFileExcel } from '@mdi/js';

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    rowFields: {
      type: Array,
      default: () => []
    },
    colFields: {
      type: Array,
      default: () => []
    },
    reducer: {
      type: Function,
      default: sum => sum + 1
    },
    reducerInitialValue: {
      default: 0
    },
    noDataWarningText: {
      type: String,
      default: 'No data to display.'
    },
    isDataLoading: {
      type: Boolean,
      default: false
    },
    filename: {
      type: String,
      default: ''
    }
  },
  setup(props, {emit}) {


    const valuesMap = ref(null)
    const labelsMap = ref(null)
    const rows = ref([])
    const cols = ref([])
    const internalRowFields = ref([])
    const internalColFields = ref([])
    const isDataComputing = ref(false)
    const computingInterval = ref(null)

    // Get value from valuesMap
    const value = (row, col) => {
      return valuesMap.value.get([...row, ...col]) || props.reducerInitialValue
    }
    // Get labels for a cell
    const labels = (row, col) => {
      return labelsMap.value.get([...row, ...col]) || []
    }
    // Get colspan/rowspan size
    const spanSize = (values, valueIndex, fieldIndex) => {
      // If left value === current value
      // and top value === 0 (= still in the same top bracket)
      // The left td will take care of the display
      if (valueIndex > 0 &&
        values[valueIndex - 1][fieldIndex] === values[valueIndex][fieldIndex] &&
        (fieldIndex === 0 || (spanSize(values, valueIndex, fieldIndex - 1) === 0))) {
        return 0
      }

      // Otherwise, count entries on the right with the same value
      // But stop if the top value !== 0 (= the top bracket has changed)
      let size = 1
      let i = valueIndex
      while (i + 1 < values.length &&
        values[i + 1][fieldIndex] === values[i][fieldIndex] &&
        (fieldIndex === 0 || (i + 1 < values.length && spanSize(values, i + 1, fieldIndex - 1) === 0))) {
        i++
        size++
      }

      return size
    }
    // Update rows/cols/valuesMap (optional)
    // @param {boolean} updateValuesMap
    const updateValues = (updateValuesMap = true) => {
      isDataComputing.value = true

      // Start a task to avoid blocking the page
      clearInterval(computingInterval.value)
      computingInterval.value = setTimeout(() => {
        const localRows = []
        const localCols = []
        const localValuesMap = new ManyKeysMap()
        const localLabelsMap = new ManyKeysMap()

        const fields = [...props.rowFields, ...props.colFields]

        props.data.forEach(item => {
          const rowKey = []
          const colKey = []
          let filtered = false

          // Check if item passes fields value filters
          for (let field of fields) {
            if (field.valuesFiltered && !field.valuesFiltered.has(field.getter(item))) {
              filtered = true
              break
            }
          }

          // Build item rowKey/colKey if necessary
          if (!filtered || updateValuesMap) {
            props.rowFields.forEach(field => {
              rowKey.push(field.getter(item))
            })

            props.colFields.forEach(field => {
              colKey.push(field.getter(item))
            })
          }

          // Update rows/cols
          if (!filtered) {
            if (!localRows.some(row => {
              return props.rowFields.every((rowField, index) => row[index] === rowKey[index])
            })) {
              localRows.push(rowKey)
            }

            if (!localCols.some(col => {
              return props.colFields.every((colField, index) => col[index] === colKey[index])
            })) {
              localCols.push(colKey)
            }
          }

          // Update valuesMap
          if (updateValuesMap) {
            const key = [ ...rowKey, ...colKey ]
            const previousValue = localValuesMap.get(key) || cloneDeep(props.reducerInitialValue)

            localValuesMap.set(key, props.reducer(previousValue, item, localRows, localValuesMap))
          }
        })

        // Update labelsMap
        localRows.forEach(row => {
          localCols.forEach(col => {
            const key = [ ...row, ...col ]
            const labels = fields.map((field, fieldIndex) => ({
              field,
              value: key[fieldIndex]
            }))
            localLabelsMap.set(key, labels)
          })
        })

        rows.value = localRows
        cols.value = localCols
        
        if (updateValuesMap) valuesMap.value = localValuesMap
        labelsMap.value = localLabelsMap

        isDataComputing.value = false
        updateInternalFields()
      }, 0)
    }
    // Update internal fields only
    const updateInternalFields = () => {
      internalRowFields.value = cloneDeep(props.rowFields)
      internalColFields.value = cloneDeep(props.colFields)
    }
    const saveTableWithText = (format) => {
      downloadTableWith(format, cols.value, props.colFields, rows.value, props.rowFields, rowHeaderSize.value, valuesMap.value, props.filename)
    }
    const sumFieldCol = (key) => {
        // sum data in give key (property)
        let sum = 0;
        for(let row in tableRows.value){
          sum += value(tableRows.value[row].row, key)
        }
        return sum;
    }
    const sumFieldRow = (key) => {
        // sum data in give key (property)
        let sum = 0;
        for(let col in sortedCols.value){
          sum += value(key, sortedCols.value[col])
        }
        return sum;
    }
    const sumFieldTotal = () => {
        // sum data in give key (property)
        let sum = 0;
        for(let col in sortedCols.value){
          for(let row in tableRows.value){
            sum += value(tableRows.value[row].row, sortedCols.value[col])
          }
        }
        return sum;
    }
    
    // Sort rows/cols using a composed function built with thenBy.js
    const sortedRows = computed( () => {
      let composedSortFunction
      internalRowFields.value.forEach((rowField, rowFieldIndex) => {
        if (rowFieldIndex === 0) {
          composedSortFunction = firstBy(0, { cmp: rowField.sort || naturalSort })
        } else {
          composedSortFunction = composedSortFunction.thenBy(rowFieldIndex, { cmp: rowField.sort || naturalSort })
        }
      })

      return [...rows.value].sort(composedSortFunction)
    })

    const sortedCols = computed( () => {
      let composedSortFunction
      internalColFields.value.forEach((colField, colFieldIndex) => {
        if (colFieldIndex === 0) {
          composedSortFunction = firstBy(0, { cmp: colField.sort || naturalSort })
        } else {
          composedSortFunction = composedSortFunction.thenBy(colFieldIndex, { cmp: colField.sort || naturalSort })
        }
      })

      return [...cols.value].sort(composedSortFunction)
    })

    // Property to watch specific field changes
    const fields = computed(() => {
      return [
        [props.colFields, props.rowFields],
        [props.colFields.map(field => field.headerSlotNames), props.rowFields.map(field => field.headerSlotNames)],
        [props.colFields.map(field => field.valuesFiltered), props.rowFields.map(field => field.valuesFiltered)]
      ]
    })
    // Number of row header columns
    const rowHeaderSize = computed(() => {
      return internalRowFields.value.reduce((acc, rowField) => {
        if (rowField.showHeader === undefined || rowField.showHeader) {
          if (rowField.headerSlotNames) return acc + rowField.headerSlotNames.length
          else return acc + 1
        }
        else return acc
      }, 0)
    })
    // Number of row footer columns
    const rowFooterSize = computed(() => {
      return internalRowFields.value.reduce((acc, rowField) => {
        if (rowField.showFooter) {
          if (rowField.footerSlotNames) return acc + rowField.footerSlotNames.length
          else return acc + 1
        }
        else return acc
      }, 0)
    })
    // Number of col header rows
    const colHeaderSize = computed(() => {
      return internalColFields.value.reduce((acc, colField) => {
        if (colField.showHeader === undefined || colField.showHeader) {
          if (colField.headerSlotNames) return acc + colField.headerSlotNames.length
          else return acc + 1
        }
        else return acc
      }, 0)
    })
    // Number of col footer rows
    const colFooterSize = computed(() => {
      return internalColFields.value.reduce((acc, colField) => {
        if (colField.showFooter) {
          if (colField.footerSlotNames) return acc + colField.footerSlotNames.length
          else return acc + 1
        }
        else return acc
      }, 0)
    })
    // Table header
    const tableHeader = computed(() => {
      const colHeaderFields = internalColFields.value.map((colField, colFieldIndex) => {
        return {
          colField,
          cols: sortedCols.value.map((col, colIndex) => ({
            value: col[colFieldIndex],
            colspan: spanSize(sortedCols.value, colIndex, colFieldIndex)
          })).filter(colField => colField.colspan !== 0)
        }
      }).filter(({ colField }) => colField.showHeader === undefined || colField.showHeader)

      return {
        colHeaderFields
      }
    })
    // Table footer
    const tableFooter = computed(() => {
      const colFooterFields = internalColFields.value.map((colField, colFieldIndex) => {
        return {
          colField,
          cols: sortedCols.value.map((col, colIndex) => ({
            value: col[colFieldIndex],
            colspan: spanSize(sortedCols.value, colIndex, colFieldIndex)
          })).filter(colField => colField.colspan !== 0)
        }
      }).filter(({ colField }) => colField.showFooter).reverse()

      return {
        colFooterFields
      }
    })
    // Table rows with row headers/footers with rowspan
    const tableRows = computed(() => {
      return sortedRows.value.map((row, rowIndex) => {
        const rowHeaderFields = internalRowFields.value.map((rowField, rowFieldIndex) => ({
          ...rowField,
          value: row[rowFieldIndex],
          rowspan: spanSize(sortedRows.value, rowIndex, rowFieldIndex)
        })).filter(rowField => (rowField.showHeader === undefined || rowField.showHeader) && rowField.rowspan !== 0)

        const rowFooterFields = internalRowFields.value.map((rowField, rowFieldIndex) => ({
          ...rowField,
          value: row[rowFieldIndex],
          rowspan: spanSize(sortedRows.value, rowIndex, rowFieldIndex)
        })).filter(rowField => rowField.showFooter && rowField.rowspan !== 0).reverse()

        return {
          row,
          rowHeaderFields,
          rowFooterFields
        }
      })
    })

    watch(
      fields,
      (val, oldVal) => {
        if (!isEqual(val[0], oldVal[0])) {
          // Fields were moved
          updateValues()
        } else if (!isEqual(val[1], oldVal[1])) {
          // Field header value filters changed
          updateInternalFields()
        } else if (!isEqual(val[2], oldVal[2])) {
          // Field header slot names changed
          updateValues(false)
        }
      }
    )
    watch(
      () => props.data,
      () => {
        updateValues()
      }
    )

    onMounted(() => {
      updateValues()
    })
    

    return {
      icons: {
        mdiFileExcel,
      },
      valuesMap,
      labelsMap,
      rows,
      cols,
      // Note: we don't use directly rowFields/colFields props to trigger table render when `updateValues` has finished
      internalRowFields,
      internalColFields,
      isDataComputing,
      computingInterval,

      sortedRows,
      sortedCols,
      fields,
      rowHeaderSize,
      rowFooterSize,
      colHeaderSize,
      colFooterSize,
      tableHeader,
      tableFooter,
      tableRows,

      value,
      labels,
      spanSize,
      updateValues,
      updateInternalFields,
      saveTableWithText,
      sumFieldCol,
      sumFieldRow,
      sumFieldTotal,
    }
  }
}
</script>

<style scoped>
.table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
    color: #3c4b64;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-condensed tr {
   line-height: 15px;
   min-height: 15px;
   height: 15px;
}

.table-condensed th, .table-condensed td{
  font-size: 12px;
  padding: 0.50rem;
  min-width: 10px;
}

.table-bordered, .table-bordered td, .table-bordered th {
  border: 1px solid;
  border-color: #d8dbe0;
}


.table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,21,.05);
}

th {
    text-align: inherit;
}
</style>
