<template>
    <v-row class="match-height">
        <v-col
        cols="12"
        md="12"
        >
        <app-card-actions
            class="mb-7"
            no-actions
            :refresh="refreshSales"
            >
                <template slot="title">
                    <span class="align-start">Sales</span>
                    
                </template>
                <v-card-text>
                    <v-row
                        class="align-center justify-center"
                    >
                        <v-btn-toggle
                            v-model="reduceAttr"
                            mandatory
                            rounded
                            dense
                            @change="reducerUpdate($event)"
                        >
                            <v-btn :value="`budget`" small>
                                <v-icon>{{icons.mdiCurrencyEur}}</v-icon>
                            </v-btn>
                            <v-btn :value="`count`" small>
                                <v-icon>{{icons.mdiNumeric}}</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </v-row>
                    <project-pivot-table
                        ref="sales_pivot_table"
                        :data="salesData"
                        :reducer="reducer"
                        :reduceAttr="reduceAttr">

                    </project-pivot-table>
                </v-card-text>  
            </app-card-actions>
        </v-col>
        <v-col
        cols="12"
        md="6"
        >
            <apex-chart
                :title="`Project Types`"
                :refresh="refreshSales"
                :data="projectTypesData"
                :type="`line`"
                :yaxisLabel="labelsFormatter"
            ></apex-chart>
        </v-col>
        <v-col
        cols="12"
        md="6"
        >
        
            <apex-chart
                :title="`Service Types`"
                :refresh="refreshSales"
                :data="serviceTypesData"
                :type="`bar`"
                :stacked="true"
                :yaxisLabel="labelsFormatter"
                :stroke="stroke"
            >

            </apex-chart>
        </v-col>
        <v-col
        cols="12"
        md="12"
        > 
            <apex-chart
                :title="`Business Initiative`"
                :subtitle="`Commercial networks and enterprises`"
                :refresh="refreshSales"
                :data="businessInitData"
                :type="`line`"
                :yaxisLabel="labelsFormatter"
            ></apex-chart>
        </v-col>
    </v-row>
    
</template>

<script>
import { ref, watch, getCurrentInstance, onMounted } from '@vue/composition-api'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import ApexChart from '@/views/components/charts/apex/ApexChart.vue'
import ProjectPivotTable from './pivot-table/ProjectPivotTable.vue'
import {  mdiCurrencyEur, mdiNumeric, mdiCalendarBlankOutline } from '@mdi/js'
import useSalesDashboard from './useSalesDashboard'
import { formatDateToMonthYearShort, removeDuplicate } from '@core/utils/filter'

export default({
    components: {
        AppCardActions,
        ProjectPivotTable,
        ApexChart,
    },
    props: {
        dates: {
            type: Array,
            default: () => [`${new Date().getFullYear()}-01-01`, `${new Date().getFullYear()}-12-31`]
        },
    },
    setup(props, {emit}) {
        const vm = getCurrentInstance().proxy

        const labelsMonth = ref([])
        const businessInitData = ref([])
        const projectTypesData = ref([])
        const serviceTypesData = ref([])
        const stroke = ref({
            curve: 'smooth',
            lineCap: 'round'
        })

        const localDates = ref([])
        localDates.value = JSON.parse(JSON.stringify(props.dates)).sort()
        const {
            refreshSales,
            salesData,
            fetchSales,
            labelsFormatter,

        } = useSalesDashboard()


        const reduceAttr = ref('budget')
        
        const reducer = (sum, item, rows, cols) => {
            return item[reduceAttr.value] ? sum + item[reduceAttr.value] : sum + 1
        }
        
        const reducerUpdate = (evt) => {
            // emit('update', evt)
            vm.$refs.sales_pivot_table.$refs.pivot_table.$refs.pivottable.updateValues(true);
        }
        
        
        const fetchSalesByDate = () => {
            const [from, to] = localDates.value
            fetchSales({
                from,
                to
            })
            
        }

        const groupDataApex = (data, transformer, xAxis, attr) => {
            // xAxis list all the elements for the x axis in the chart (labels).
            // Go through each element of xAxis, if elemt date isn't in data (filter)
            // them reducer initial value is 0 for budget
            return xAxis.map((date) => {
                return {
                x: date,
                y: data.filter(el => transformer(el.date) === date)
                    .map(el => {
                    const value = el[attr] ? el[attr] : 1
                    return value
                    })
                    .reduce((total, sale) => total + sale, 0).toFixed(0)
                }
            })
        }

        const formatSeries = (key, attr) => {
            
            const datakey = [...new Set(salesData.value.map(item => item[key]))];
            let datasets = []

            datakey.map((item, index) => {
                datasets.push({
                    name: item,
                    data: groupDataApex(salesData.value.filter(el => el[key] === item), formatDateToMonthYearShort, labelsMonth.value, attr),
                })
            })

            return datasets
        }

        watch(
            () => props.dates,
            (from, to) => {
                if (from !== to) {
                    localDates.value = JSON.parse(JSON.stringify(props.dates)).sort()
                    fetchSalesByDate()
                }
            }
        )

        watch(
            salesData,
            () => {
                labelsMonth.value = salesData.value.map(entry => formatDateToMonthYearShort(entry.date))
                          .reduce(removeDuplicate, [])
                businessInitData.value = formatSeries('business_initiative', 'budget', true)
                serviceTypesData.value = formatSeries('service_type', 'budget', true)
                projectTypesData.value = formatSeries('project_type', 'budget', true)

                
                let totalSales = 0
                for (const item of salesData.value){
                    totalSales += item.budget
                }
                emit('update-data', 'sales', totalSales)
            }
        )

        onMounted(() => {
            fetchSalesByDate()
        })
        

        return {
            labelsFormatter,
            localDates,
            refreshSales,
            salesData,
            fetchSalesByDate,
            reduceAttr,
            businessInitData,
            serviceTypesData,
            projectTypesData,
            icons: {
                mdiCurrencyEur,
                mdiNumeric,
                mdiCalendarBlankOutline,
            },
            stroke,
            reducer,
            reducerUpdate,
        
        }
    },
})
</script>


<style lang="scss">
$enable-rounded: false;
$table-cell-padding: .5rem; // default in bs5
$table-cell-padding-sm: .25rem; // default in bs5
// @import '~bootstrap/scss/bootstrap.scss';

/* Table with aria-busy attribute */
table[aria-busy='true'] {
  opacity: 0.6;
}

/* FontAwesome icons */
.svg-inline--fa.fa-fw {
  width: 1.25em;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -.125em !important;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>

  
